.section.mission-statement {
	min-height: 75vh;
}
.mission-statement .gray-background {
	padding: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
.mission-statement h2 {
	font-size: 2.3rem;
}
.mission-statement h3 {
	font-size: 2rem;
	font-weight: bold;
	margin: 1.2rem 0;
	font-family: 'proxima-soft', sans-serif;
}
.mission-statement .card {
	background: #f5f5f5;
	border-radius: 12px;
}
.mission-statement .card-body {
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 980px){
	.section.mission-statement {
		padding: 0;
		max-width: 83%;
		justify-content: flex-start;
		min-height: unset;
	}
	.section.mission-statement .col-12 {
		padding: 0;
	}
	.mission-statement h2 {
		font-size: 1.6875rem;
	}
	.mission-statement h3 {
		font-size: 1rem;
		margin: 0;
	}
	.mission-statement .gray-background {
		width: 100%;
		padding: 0;
		min-height: unset;
	}
	.mission-statement .card-body {
		padding: 2rem 0.5rem;
	}
}