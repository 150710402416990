.section.three-uniques {
	position: relative;
}

.section.three-uniques h2 {
	font-size: 2.3rem;
	text-align: left;
}
.section.three-uniques .uniques-wrapper {
	padding: 2rem 0;
}
.section.three-uniques .uniques-wrapper .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.section.three-uniques .col-5 {
	padding-right: 2rem;
	padding-left: 0;
}
.section.three-uniques .bubble {
	width: 100%;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 1.5vw;
	height: 10.4vw;
}
.section.three-uniques .bubble h3 {
	color: white;
	font-size: 2.7vw;
	margin: 0;
}
.section.three-uniques .bubble.primary {
	background-color: #436982;
}
.section.three-uniques .bubble.secondary {
	background-color: #61B4E4;
}
.section.three-uniques .bubble.tertiary {
	background-color: #79BDEA;
}

.section.three-uniques p {
	font-family: 'proxima-soft', sans-serif;
	font-size: 1.2vw;
	line-height: 2vw;
	margin: 0;
}
.section.three-uniques .send-to-front {
	position: relative;
	z-index: 10;
	background-color: transparent;
}
.section.three-uniques .send-to-back {
	position: absolute;
	top: 0;
	z-index: 0;
}
.section.three-uniques .grid-image {
	overflow: hidden;
	width: auto;
	height: 100%;
	opacity: 0.7;
	transform: rotate(15deg);
}
.section.three-uniques .grid-image image {
	height: 100%;
	width: auto;
}
@media screen and ( max-width: 980px ) {
	.section.three-uniques h2 {
		font-size: 1.6875rem;
		text-align: left;
	}
	.section.three-uniques .uniques-wrapper .row {
		flex-direction: column;
	}
	.section.three-uniques .col-5 { 
		padding: 15px 0;
	}
	.section.three-uniques .col-7 { 
		padding: 15px 0;
	}
	.section.three-uniques .bubble {
		border-radius: 12px;
		height: unset;
		padding: 2rem;
	}
	.section.three-uniques .bubble h3 { 
		font-size: 1.6875rem;
	}
	.section.three-uniques .bubble br{
		display: none;
	}
	.section.three-uniques p { 
		font-size: 1rem;
		line-height: 1.1em;
	}
}