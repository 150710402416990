.section.licenses {
	padding: 0;
	min-height: 80vh;
	justify-content: flex-start;
	margin-top: 30px;
}
.licenses .row {
	max-width: unset;
	width: 100%;
}
.licenses [class*="col-"] {
	padding: 0;
}
.licenses .blue-header-block {
	background-color: #61B4E4;
	padding: 110px 10%;
	text-align: left;
	width: 100%;
	margin-bottom: 60px;
}
.licenses .blue-header-block h2 {
	color: white;
	text-align: left;
}
.licenses .item-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
}

.licenses .license-details {
	padding: 12px 10%;
	width: 100%;
}
.licenses .item-img {
	display: flex;
	justify-content: center;
}
.licenses .item-img img {
	width: 110%;
}
.licenses .card-img {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	overflow: visible;
	height: 70%;
	position: relative;
	display: flex;
	flex-direction: row !important;
	align-items: flex-end;
	box-shadow: none;
	background-color: rgb(245,245,245);
}

@media screen and (max-width: 980px) {
	.licenses .grid-become-agent {
		width: 100% !important;
		margin: 0 !important;
	}
	.licenses .item-wrapper {
		padding: 0 !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.licenses .item-text {
		max-width: 90%;
		align-items: center;
	}
	.licenses .license-details {
		padding: 12px;
	}
}