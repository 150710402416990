body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.main-content {
  box-shadow: none;
}
header .MuiListItem-root_ALT a:visited{
  color: inherit;
}
header .MuiListItem-root a:hover {
  color: rgba(0,0,0,0.5);
}
footer .MuiListItem-root a {
  color: rgba(153,153,153,1);
}
footer .MuiListItem-root_ALT a:visited{
  color: inherit;
}
footer .MuiListItem-root a:hover {
  color: rgba(204,204,204,1);
}
footer .subscribe-div h4 {
  color: #FFF;
}
footer .footer-socials i {
  font-size: 32px !important;
}
footer .fair-housing-icon {
  margin-top: 1rem;
}

.client-form-btn {
  position: relative;
  z-index: 15 !important;
}

.agent-form-btn {
  position: relative;
  z-index: 15 !important;
}