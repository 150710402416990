.section.the-team .team-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	padding: 15px 0;
}
.section.the-team h2 {
	font-size: 2.3rem;
	text-align: left;
}
.section.the-team .team-wrapper .card-wrapper {
	padding: 0;
	margin: 0 2%;
}

.section.the-team .team-wrapper .card-wrapper:first-of-type {
	margin-left: 0;
}

.section.the-team .team-wrapper .card-wrapper:last-of-type {
	margin-right: 0;
}

.section.the-team .team-wrapper .card-wrapper .card {
	border-radius: 24px;
	overflow: hidden;
}

.section.the-team .team-wrapper .card-wrapper .card-body { 
	padding: 1rem 0.25rem;
	background-color: #f5f5f5;
}

.section.the-team .team-wrapper h3 {
	font-family: 'proxima-soft', sans-serif;
	font-size: 1.5vw;
	text-align: center;
	margin: 0;
}
.section.the-team .team-wrapper h4 {
	font-family: 'proxima-soft', sans-serif;
	font-size: 1.2vw;
	font-style: italic;
	text-align: center;
	font-weight: normal;
	margin: 0;
}

@media screen and ( max-width: 980px ) {
	.section.the-team .team-wrapper {
		flex-wrap: wrap;
	}
	.section.the-team .team-wrapper .card-wrapper {
		width: 50%;
		margin: 0;
		padding: 0 2%;
	}
	.section.the-team h2 {
		font-size: 1.6875rem;
	}
	.section.the-team .team-wrapper h3 {
		font-size: 0.9rem;
	}
	.section.the-team .team-wrapper h4 {
		font-size: 0.7rem;
	}
}