
.privacy-page {
	padding: 0 100px 0 100px;
	margin-bottom: 64px;
}
.privacy-page > div {
	max-width: 1224px;
}
.privacy-page .section {
	padding: 64px 0;
}
.privacy-page table th {
	background-color: hsla(0,0%,50.2%,.0705882353)
}
.privacy-page table th, .privacy-page table td {
	border: 1px solid 1px solid hsla(0,0%,50.2%,.5019607843);
	padding: 2px 6px;
}
.privacy-page a {
	margin-left: 3px;
	margin-right: 3px;
}
.privacy-page .contact-block {
	width: 100%;
	text-align: left;
	line-height: 1.05em;
}

@media screen and (max-width: 1224px){
	.privacy-page {
		padding: 0 24px 0 24px;
		margin-bottom: 48px;
	}
	.privacy-page > div {
		max-width: 100%;
	}
}

@media screen and (max-width: 767px){
	.privacy-page {
		padding: 0 16px 0 16px;
		margin-bottom: 32px;
	}
}
