.border-fullwidth {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	margin-top: 15px;
	margin-bottom: 0;
	padding: 0 !important;
	height: 80px;
	overflow: hidden;
	
}

.border-horz {
	object-fit: cover;
	object-position: center center;
	width: 100%;
}
