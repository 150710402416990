.section.core-values {
	background-color: #f5f5f5;
}

.section.core-values h2 {
	text-align: center;
	font-size: 2.3rem;
}

.section.core-values .quote-header h3 {
	text-align: left;
	font-size: 2rem;
}

.section.core-values .quote-body p {
	text-align: left;
	font-size: 1rem;
	font-style: italic;
	font-family: 'proxima-soft', sans-serif;
}
.section.core-values .socials-statement {
	max-height: 200px;
}
.section.core-values .socials-statement h4 {
	font-family: 'blithe', sans-serif;
	font-size: 2rem;
}
.section.core-values .socials-statement.rotate-text {
	rotate: -15deg;
}
@media screen and ( max-width: 980px ) {
	.section.core-values .core-values-wrapper {
		padding: 15px 0;
	}
	.section.core-values h2 {
		font-size: 1.6875rem;
	}
	
	.section.core-values .quote-header h3 {
		font-size: 1.6875rem;
	}

}