.video-main {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
}

.video-main .div {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 240px; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
ul,
li {
  font-family: "Adelle-sans", sans-serif;
}

.video-main .boxed-1440 {
  max-width: 1440px;
}
.video-main .boxed-800 {
  max-width: 800px;
}
.video-main h1 {
  color: "#000";
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5em;
  width: 100%;
}
.video-main h2 {
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.5em;
  width: 100%;
}
.video-main .video {
  width: 100%;
  /* max-width: 720px; */
  border-radius: 8px;
  aspect-ratio: 16 / 9;
  height: auto;
}

.video-main .video-div {
  padding-bottom: 24px;
  gap: 24px;
}
.video-main hr {
  width: 100%;
  color: rgba(0, 0, 0, 0.03);
  height: 4px;
  border: none;
  border-radius: 6px;
}
.video-main .transcript-div {
  padding: 0 0 48px 0;
}

.video-main .transcript-div h2 {
  margin-top: 0;
  text-align: left;
}

.video-main .transcript-div h3 {
  text-align: left;
}

.video-main .flex {
  display: flex;
}
.video-main .flex-row {
  flex-direction: row;
  flex-wrap: wrap;
}
.video-main .flex-row > div {
  width: 100%;
}
.video-main .flex-column {
  flex-direction: column;
}
.video-main .justify-center {
  justify-content: center;
}
.video-main .align-center {
  align-items: center;
}
.video-main .justify-left {
  justify-content: flex-start;
}
.video-main .align-left {
  align-items: flex-start;
}
.hide {
  display: none !important;
}
@media screen and (max-width: 980px) {
  .video-main h1 {
    font-size: 1.8rem;
  }
  .video-main h2 {
    font-size: 1.5rem;
  }
  .video-main p {
    font-size: 0.875rem;
  }
}
