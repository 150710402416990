
/* Colors */
$prim_1: #436982;
$prim_2: #61B4E4;
$altBlue_1: #79BDEA;
$altBlue_2: #A6D3F1;
$red_1: #F15A24;
$orange_1: #F7931E;
$tan_1: #C69C6D;


/* Masonry Grid */
$masGridCols: 5;
$masonryGridGap: 18px;
$masBorderRad: 20px;

$masGridColsMobile: 3;
$masonryGridGapMobile: 4px;
$masBorderRadMobile: 12px;

$masGridWid: calc(100% - (2 * $masonryGridGap));
$masGridItemWid: calc( 100% / $masGridCols);

$masGridWidMobile: calc(100%);
$masGridItemWidMobile: calc( 100% / $masGridColsMobile);

/* Stamps */
$stmpWid: calc(3 * ($masGridItemWid - ( 2 * $masonryGridGap)));
$stmpLeft: calc((100% - $stmpWid) / 2);

.pageDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(245,245,245);
	width: 100%;
}
.main-content {
	margin-top: 95px !important;
	width: 100%;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.section {
	padding: 6em 4em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section {
	width: 100%;
}

.col-1 {
	width: 8.33%;
}

.col-2 {
	width: 16.66%;
}

.col-3 {
	width: 25%;
}

.col-4 {
	width: 33.33%;
}

.col-5 {
	width: 41.66%;
}

.col-6 {
	width: 50%;
}

.col-7 {
	width: 58.33%;
}

.col-8 {
	width: 66.66%;
}

.col-9 {
	width: 75%;
}

.col-10 {
	width: 83.33%;
}

.col-11 {
	width: 91.66%;
}

.col-12 {
	width: 100%;
}

[class*="col-"] {
	float: left;
	padding: 15px;
}

.row {
	width: 100%;
}
.section .row {
	max-width: 1440px;
}

.row::after {
	content: "";
	clear: both;
	display: table;
}

.row h2 {
	text-align: center;
}
.fullwidth {
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	width: 100% !important;
}
.faq-title, h1, h2, h3, h4, h5, h6, p {
	color: #000
}

.masCont {
	position: relative;
	max-height: 80vh;
	overflow: hidden;
}

.masOuterCont {
	position: relative;
	min-height: 80vh;
	padding: 0;
	display: block !important;
}

.masImg {
	width: 100%;
}

.masCont .mobileStampHeader h1 {
	color: #FFF;
}

.grid {
	width: $masGridWid;
	min-height: 800px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}

.gridSizer {
	width: $masGridItemWid;
}

.gutterSizer {
	width: $masonryGridGap;
}

.grid-item {
	width: $masGridItemWid; //number of grid cols determine width
	aspect-ratio: 1;
	padding: calc($masonryGridGap / 2);
}
/*
.gridItem {
	width: $masGridItemWid; //number of grid cols determine width
	aspect-ratio: 1;
	padding: calc($masonryGridGap / 2);
}
*/
.gridItem .itemInner {
	position: relative;
	overflow: hidden;
	border-radius: $masBorderRad;
	background-color: rgba(100,100,100, 0.7);
	height: 100%;
	z-index: 5;
}

.gridItem .itemInner:hover {
	transform: translateY(2px);
}

.itemBubble {
	position: absolute;
	right: calc(0px - $masonryGridGap / 4 );
	top: calc(0px - $masonryGridGap / 4 );
	width: 30%;
	aspect-ratio: 1/1;
	border-radius: 50%;
	border: 7px solid $prim_2;
	overflow: hidden;
	opacity: 0.85;
	transition: all 0.3s;
	z-index: 9;
}

.itemBubble:hover {
	opacity: 1;
}

.itemBubble .inner {
	overflow: hidden;
	border-radius: 50%;
	border: 3px solid white;
	width: 100%;
	height: 100%;
	background-color: $altBlue_2;
}

.itemBubble img {
	width: 100%;
}

.stamp {
	position: absolute;
}

.stampHeader {
	width: $stmpWid;
	height: 16em;
	left: $stmpLeft;
	top: 1em;
	text-align: center;
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.stampHeader h1 {
	margin: 0.5em;
	font-size: 3em;
}

.stampVertical {
	height: 3em;
	width: calc($masGridItemWid - 2 * $masonryGridGap);
	top: calc(3em + 16em);
	left: calc((100% - ($masGridItemWid - 2 * $masonryGridGap)) / 2);
	margin-bottom: 30px;
}

.botFadeDown {
	position: absolute;
	width: 100%;
	height: 120px;
	left: 0;
	bottom: 0;
	z-index: 10;
	background: linear-gradient(transparent, rgba(255,255,255,0.4),rgba(255,255,255,0.9), white);
}

[class*=btn-] {
	padding: 0.7em 3em;
	font-size: 1.2rem;
	font-family: 'proxima-soft', sans-serif;
	border: 0;
	border-radius: 6px;
	transition: all 0.3s;
	cursor: pointer;
	background-color: transparent;
}

[class*=btn-]:hover {
	box-shadow: 0 0 3px 3px #ddd;
}

.btn-medium {
	padding: 0.5em 3em;
	font-size: 1.5em;
}

.btn-whiteOnBlue {
	background-color: $prim_2;
	color: white;
}

.btn-whiteOnBlue:hover {
	background-color: white;
	color: $prim_2;
}
hr {
	background-color: #ddd;
	border-color: #ddd;
	border-top: 1px solid #ddd;
	color: #ddd;
	width: 100%;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}

.text-standard {
	font-size: 1.4em;
	line-height: 2em;
}

.boxed {
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	z-index: 5;
}

[class*="logo-placement"] img {
	width: 130px;
	height: auto;
}


.text-white * {
	color: white;
}

button.btn-medium {
	padding: 0.5rem 3rem;
	font-size: 1.5rem;
}

[class*="white-on-"] {
	color: white;
}

[class*="-on-orange"] {
	background-color: #F7931E;
}

[class*="-on-grey"] {
	background-color: #ddd;
}

button.white-on-orange:hover {
	color: #F7931E;
	background-color: white;
	box-shadow: 0 0 3px 3px #ddd;
}

button.white-on-grey:hover {
	color: white;
	background-color: #61B4E4;
	box-shadow: 0 0 3px 3px rgb(70, 70, 70);
}

.text-justify {
	text-align: justify;
}

.put-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.bk-color-primary {
	background-color: #436982;
	color: white;
}

.bk-color-secondary {
	background-color: #61B4E4
}

.bk-color-blue-1 {
	background-color: #61B4E4;
}

.bk-color-blue-2 {
	background-color: #79BDEA;
}

.bk-color-blue-3 {
	background-color: #A6D3F1;
}

.bk-color-beige {
	background-color: #C69C6D;
	color: white;
}

.pagination-bullet-round {
	margin: 0 10px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	cursor: pointer;
	display: block;
}

.pagination-bullet-round:hover {
	background-color: #F7931E;
}

.pagination-bullet-round.enable {
	background-color: #61B4E4;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0.5;
	background-color: rgb(50, 50, 50);
}

.overlay-primary {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #436982;
	opacity: 0.85;
	z-index: 1;
}

.page-arrow {
	position: absolute;
	z-index: 10;
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 6px;
	padding-right: 6px;
}

.arrow-left {
	left: 0;
}

.arrow-right {
	right: 0;
}


.animationSlideLeftOut {
	opacity: 0;
	transform: translateX(-100vw);
	-webkit-animation: 2000ms slideLeftFadeOut ease-in;
	-moz-animation: 2000ms slideLeftFadeOut ease-in-out;
	-ms-animation: 2000ms slideLeftFadeOut ease-in-out;
	-o-animation: 2000ms slideLeftFadeOut ease-in-out;
	animation: 1350ms ease-in slideLeftFadeOut;
}

.animationSlideLeftIn {
	opacity: 1;
	transform: translateX(0);
	-webkit-animation: 2000ms slideLeftFadeOut ease-in;
	-moz-animation: 2000ms slideLeftFadeOut ease-in-out;
	-ms-animation: 2000ms slideLeftFadeOut ease-in-out;
	-o-animation: 2000ms slideLeftFadeOut ease-in-out;
	animation: 1200ms ease-out 150ms slideLeftFadeIn;
}


section.contact-us {
	height: 100vh;
}

.form-container {
	max-width: 400px;
	margin: 0 auto;
	padding: 20px;
}

.form-container h2 {
	text-align: center;
}

.form-container form {
	display: flex;
	flex-direction: column;
}

.form-container label {
	margin-top: 10px;
	display: none;
}

.form-container input {
	padding: 1em;
	margin-top: 5px;
	border: 1px solid rgba(200, 200, 200, 0.7);
	border-radius: 3px;
}

.form-container input:focus {
	outline: 1px solid #F7931E;
}

.form-container button {
	margin-top: 1em;
	margin-bottom: 2em;
}


.flex-stacked {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

[class*="align-"] {
	display: flex;
}

.align-h {
	justify-content: center;
}

.align-v {
	/* height: 100%; */
	align-items: center;
}

.mobile-only {
	display: none;
}

footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 3rem 0 4rem 0 !important;
	margin-top: 0;
}

.footer-container {
	width: 1140px;
	display: flex;
	flex-direction: column;
}

.footer-socials {
	display: flex;
	flex-direction: column;
}

.footer-socials ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
}
.footer-links {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding: 2em 0;
}
.footer-links ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	flex-wrap: wrap;
}
.footer-links a {
	font-size: 1rem;
	color: rgba(255,255,255,0.5);
}
.subscribe-div h4 {
	font-size: 1.4rem;
}
.subscribe-div p {
	line-height: 0.9em;
}
#subscribe-form1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 2.7rem;
}
#subscribe-form1 .text-input {
	height: 100%;
	border: 1px solid rgba(255,255,255,0.2);
	border-radius: 6px;
	padding: 3px;
	margin-right: 10px;
}
#subscribe-form1 input {
	border: none;
	background-color: transparent;
	color: rgba(255,255,255,0.2);
	height: 100%;
	width: 100%;
}
#subscribe-form1 button {
	height: 100%;
	color: #585858 !important;
	background-color: white !important;
	text-transform: capitalize;
	position: relative;
}
#subscribe-form1 button:hover {
	color: white !important;
	background-color: $prim_2 !important;
}
#subscribe-form1 button.no-hover:hover {
	color: $prim_2 !important;
	background-color: white !important;
}
.logo-wide, .logo-wide svg {
	width: 100%;
	max-width: 200px;
}
.logo-white svg {
	fill: white;
}
.logo-blue svg {
	fill: $prim_2 !important;
}
.logo-blue svg path, .logo-blue svg text {
	fill: $prim_2 !important;
}

.left-aligned {
	text-align: left;
}

.disclaimer.left-aligned {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.disclaimer img {
	width: 60px;
}
.disclaimer p {
	line-height: 1em;
}
header.header-color-change .header-btn {
	color: $prim_2 !important;
	background-color: white !important;
}
header.header-color-change .header-btn:hover {
	color: $prim_2 !important;
	background-color: #f5f5f5 !important;
}

.mobile-header-button {
	padding: 6px 10px !important;
}
.mobile-header-button.white-back {
	color: $prim_2 !important;
	background-color: #FFF !important;
}
.mobile-header-button.white-back:hover {
	color: $prim_2 !important;
	background-color: #f5f5f5 !important;
}

.footer-socials svg {
	width: 32px !important;
	height: 32px !important;
	color: #FFF;
}

@media screen and (max-width: 1310px) { 
	.stampHeader h1 {
		font-size: 2em;
	}
}

@media screen and (max-width: 980px) {
	.section {
		padding: 2rem 35px;
		margin: unset;
	}
	.main-content {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	h1, h2 {
		line-height: 1.5em;
		text-align: center;
		font-size: 2.2rem;
	}
	
	h3, h4, h5 ,h6 {
		text-align: center;
		font-size: 1.7rem;
	}

	p {
		text-align: justify;
		font-size: 1rem;
	}

	footer .footer-container {
		width: 100%;
	}

	footer .row {
		display: flex;
		flex-direction: column;
	}
	.MuiPaper-root .MuiListItem-root .client-form-btn {
		width: 100%;
	}
	.moving-with-the-military p, .moving-with-the-military h2 {
		text-align: left;
	}
	.moving-with-the-military .lone-cta {
		justify-content: flex-start;
	}
	.conversation p, .conversation h2 {
		text-align: right;
	}
	.conversation button {
		height: 2em;
	}

	section:not(.hero) {
		padding-top: 10vh;
		padding-bottom: 10vh;
	}

	section.hero .boxed {
		height: calc(100vh - 80px);
		justify-content: space-evenly;
	}
	section.hero .logo-placement {
		position: absolute;
		top: 20px;
	}

	section img {
		max-width: 450px;
	}
	section.hero .logo-placement img {
		width: 20vw;
		max-width: unset;
		min-width: 80px;
	}
	.top-header .contact-header {
		flex-direction: column;
		gap: unset;
		justify-content: space-evenly;
	}
	.logo-wide, .logo-wide svg {
		max-width: 120px;
	}
	.top-header .row {
		flex-direction: row-reverse;
	}
	.top-header a.email-link {
		display: none;
	}

	.lone-img {
		display:flex;
		justify-content: center;
	}

	.carousel.reviews {
		height: unset;
	}

	.lone-cta {
		display: flex;
		justify-content: center;
	}

	.colored-blocks>div {
		display: flex;
		flex-direction: column;
	}

	[class*="col-"] {
		width: 100%;
	}

	.moving-with-the-military .img-col {
		display: none;
	}

	.spacer-10pct {
		height: 0;
	}

	.page-arrow {
		align-items: flex-end;
		display: none;
	}

	.quote-mark {
		display: none;
	}

	.quote-mark svg {
		height: 2em;
	}

	.quote-inner {
		padding-top: 10%;
		align-items: flex-end;
	}

	.quote-inner p {
		font-size: 0.9em;
		line-height: 1.2em;
		margin-top: 0;
		margin-bottom: 0;
	}

	.quote-creds {
		font-size: 1em;
	}

	.how-it-works h2 {
		margin-bottom: 0.5em;
	}

	.colored-blocks .even-block {
		padding: 40px 12px;
	}

	.colored-blocks .even-block .inside-block {
		text-align: center;
	}
	.colored-blocks .even-block .inside-block p {
		text-align: center;
	}

	.accordion-header {
		font-size: 1em;
		line-height: 1em;
	}

	.flex-row {
		flex-direction: column;
	}

	.footer {
		text-align: center;
	}
	.section.masOuterCont {
		margin-top: 0;
		padding: 0 !important;
		min-height: unset;
	}
	.masCont {
		max-height: 80vh;
	}
	.mobile-only {
		display: block;
	}
	.desktop-only {
		display: none !important;
	}
	.mobile-only.mobileStampHeader {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex !important;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		background-color: rgba(0,0,0,0.5);
		z-index: 20;
	}
	.stampHeader {
		display: none;
		width: 0;
		height: 0;
		left: 0;
	}
	.mobileStampHeader h1 {
		font-size: 27px !important;
		padding-left: 12px;
		padding-right: 12px;
	}
	.stampVertical {
		width: 0;
		height: 0;
		top: 0;
	}
	.grid {
		width: calc($masGridWidMobile - $masonryGridGapMobile );
	}
	.gridSizer {
		width: $masGridItemWidMobile;
	}	
	.grid-item {
		width: $masGridItemWidMobile; //number of grid cols determine width
		padding: calc($masonryGridGapMobile / 2);
	}
	.gutterSizer {
		width: 0;
	}
	.gridItem {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		border-radius: 12px;
	}
	.gridItem .itemInner {
		border-radius: $masBorderRadMobile;
	}
	.gridItem img {
		max-height: 100vw;
	}
	.itemBubble {
		display: none;
		border: 2px solid $prim_2;
		top: 0;
		right: 0;
	}
	.itemBubble .inner {
		border: 1px solid white;
	}
	.botFadeDown {
		display: none;
	}
	.disclaimer {
		padding: 0 3vw;
	}
	#subscribe-form1 {
		justify-content: center;
	}
	.subscribe-div > div p {
		text-align: center;
	}
}