.pageDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
}
.main-content.agents-main {
	max-width: 100%;
	width: 100%;
	box-shadow: none;
}
.main-content.agents-main .section {
	padding-left: 0;
	padding-right: 0;
	display: flex;
	justify-content: center;
}
.main-content.agents-main .section > .row {
	max-width: 1440px;
}

.main-content_OLD {
	padding: 8em 4em;
	display: flex;
	flex-direction: column;
	max-width: 1600px;
}
.card-animate:hover {
	transform: translate(1px, -1px);
	box-shadow: 0 1px 3px 3px rgba(150,150,150,0.4);
}
.main-content.agents-main p {
	font-size: 1.0rem;
}

@media screen and (max-width: 1480px) {
	div.main-content.agents-main {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media screen and ( max-width: 980px) {
	div.main-content.agents-main {
		margin-top: 96px !important;
		padding-left: 0;
		padding-right: 0;
	}

}