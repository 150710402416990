.section.our-story h2 {
	font-size: 2.3rem;
	text-align: left;
	margin-bottom: 1em;
}

.section.our-story p {
	font-size: 1.2vw;
	text-align: left;
	font-family: 'proxima-soft', sans-serif;
	margin-bottom: 1em;
	line-height: 1.5em;
}

.our-story .item-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/*max-width: 512px;*/
}

@media screen and (max-width: 980px) {
	.section.our-story .our-story-col {
		padding: 15px 0;
	}

	.our-story .item-text {
		max-width: 100%;
		align-items: flex-start;
	}
	.section.our-story h2 {
		font-size: 1.6875rem;
	}
	
	.section.our-story p {
		font-size: 1rem;
	}
}